import { colors } from "@/styles/global.styles";
import { useUnreadCallsCount } from "@/utils/hooks/useUnreadCalls";
import { useUnreadMessagesCount } from "@/utils/messaging/conversation/conversationUtils/useUnreadMessages";
import { css } from "@emotion/react";
import { AnimatePresence, motion } from "motion/react";
import { useEffect, useRef, useState } from "react";
import { useUnmount } from "usehooks-ts";

const notificationBadgeCss = css({
  color: "white",
  background: colors.primaryAccentColor,
  fontSize: "0.75em",
  fontWeight: "bold",
  borderRadius: "50%",
  width: "8px",
  height: "8px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "absolute",
  top: "4px",
  right: "4px",
  padding: "4px",
  boxSizing: "content-box",
  transform: "translate(50%, -50%)",
  boxShadow: `0 0 0 2px hsl(213 9% 23%)`,
  overflow: "hidden",
});

function NotificationBadge({
  notificationCount,
}: {
  notificationCount: number;
}) {
  const above9 = notificationCount > 9;
  const above99 = notificationCount > 99;
  const notificationCountString = above99
    ? "99+"
    : notificationCount.toFixed(0);

  // Use px instead of em here to bypass minimum acceptable font size set by browser (mostly on Mac)
  return (
    <motion.div
      css={notificationBadgeCss}
      style={
        above99
          ? { fontSize: "8px" }
          : above9
            ? { fontSize: "10px" }
            : undefined
      }
      animate={{ opacity: 1 }}
      transition={{ duration: 0.25 }}
      exit={{ opacity: 0 }}
    >
      {notificationCountString}
    </motion.div>
  );
}

function NotificationBadgeWithIconWrapper({
  notificationCount,
  children,
}: {
  notificationCount: number;
  children: React.ReactNode;
}) {
  const [count, setCount] = useState(0);

  const timerRef = useRef<number | undefined>(undefined);

  useEffect(() => {
    // Delay the display of the count, in case we receive the call log from nms but directly read it (if we are already on the call log page avoiding badge to flash 1 then disappear)
    // This also allows a fake batch update, since we receive call logs update with seen flag one by one, this avoid seeing on the UI for 10 missed calls, ui being update with 10 9 8 7 .... 1
    // eslint-disable-next-line @eslint-react/web-api/no-leaked-timeout
    timerRef.current = window.setTimeout(() => {
      setCount(notificationCount);
    }, 1000);
  }, [notificationCount]);

  useUnmount(() => {
    clearTimeout(timerRef.current);
  });

  return (
    <div
      css={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
        width: "fit-content",
      }}
    >
      {children}
      <AnimatePresence>
        {count > 0 && <NotificationBadge notificationCount={count} />}
      </AnimatePresence>
    </div>
  );
}

export function CallsNotificationBadge({
  children,
}: {
  children: React.ReactNode;
}) {
  const unreadCount = useUnreadCallsCount();

  return (
    <NotificationBadgeWithIconWrapper notificationCount={unreadCount}>
      {children}
    </NotificationBadgeWithIconWrapper>
  );
}

export function MessagesNotificationBadge({
  children,
}: {
  children: React.ReactNode;
}) {
  const unreadCount = useUnreadMessagesCount();

  return (
    <NotificationBadgeWithIconWrapper notificationCount={unreadCount}>
      {children}
    </NotificationBadgeWithIconWrapper>
  );
}
